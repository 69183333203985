import React from "react";
import { graphql } from "gatsby";
import { css, jsx } from "@emotion/react";
import { Link } from "gatsby";

const BREAKPOINTS = {
  xs: "420px",
  sm: "576px",
  md: "768px",
  lg: "900px",
  xl: "1200px",
  xxl: "1536px",
};

const container = css`
  display: flex;
  flex-flow: column;
  justify-content: start;
  @media (min-width: ${BREAKPOINTS.xs}) {
    margin: 12px 5%;
  }
  @media (min-width: ${BREAKPOINTS.md}) {
    margin: 12px 10%;
  }
  @media (min-width: ${BREAKPOINTS.lg}) {
    margin: 12px 15%;
  }
  @media (min-width: ${BREAKPOINTS.xl}) {
    margin: 12px 30%;
  }
`;

const blogPostHeader = css`
  color: darkslategray;
  font-size: 20px;

  font-family: -apple-system, "Roboto", sans-serif, serif;
`;

const blogPostDate = css`
  color: slategray;
  font-size: 14px;
  font-weight: lighter;
  font-family: -apple-system, "Roboto", sans-serif, serif;
`;

const timeToReadStyle = css`
  color: lightslategray;
  font-size: 14px;
  font-weight: lighter;
  font-family: -apple-system, "Roboto", sans-serif, serif
  font-style: italic;
  margin-left: 12px;
`;

const blogPostContent = css`
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-weight: normal;
`;

const link = css`
  color: darkslategray;
  display: block;
  align-self: center;
  font-weight: normal;
  padding-bottom: 4px;
  font-size: 18px;
  text-decoration: none;
  font-family: "Times New Roman", Times, serif;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

function BlogPostTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html, timeToRead } = markdownRemark;
  return (
    <div css={container}>
      <div>
        <h1 css={blogPostHeader}>{frontmatter.title}</h1>
        <div>
          <span css={blogPostDate}> {frontmatter.date} </span>
          <span css={timeToReadStyle}>{timeToRead} minute read</span>
        </div>
        <div css={blogPostContent} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <Link css={link} to={"/posts"}>
        Back to Posts
      </Link>
    </div>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
